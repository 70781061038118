"use client";
import React, { AnchorHTMLAttributes } from "react";
import Link, { LinkProps } from "next/link";
import { usePathname } from "next/navigation";
import { analytics } from "lib/segment"; 

interface LinkTrackersProps extends LinkProps {
  target?: AnchorHTMLAttributes<HTMLAnchorElement>["target"];
  className?: string;
  segmentMsg: string;
  segmentOpt: Record<string, any>;
  children: React.ReactNode;
}

export const LinkTrackers: React.FC<LinkTrackersProps> = ({
  href,
  target = "_self",
  className,
  segmentMsg,
  segmentOpt,
  children,
  ...linkProps 
}) => {
  const pathname = usePathname();

  const handleOnClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    if (target === "_blank") e.preventDefault();

    analytics.track(segmentMsg, { ...segmentOpt, url: pathname });

    if (target === "_blank") {
      window.open(href.toString(), target);
    }
  };

  return (
    <Link
      href={href}
      {...linkProps}
      target={target}
      onClick={handleOnClick}
      className={className}
    >
      {children}
    </Link>
  );
};
