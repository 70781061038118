"use client";
import { deleteCookie } from "cookies-next";

const Cookies = () => {
  // This Cookie from external site is causing issues. Programmtically deleting it on our site
  deleteCookie("s_sq");

  return <div></div>;
};

export default Cookies;
