"use client";
import React from "react";
import { AspectRatio } from "~/components/ui/aspect-ratio";
import Image from "next/image";
import { Button } from "~/components/ui/button";
import { ArrowLongRight } from "~/components/icons";
import { LinkTrackers } from "~/components/reusables/link-trackers";
import { usePathname } from "next/navigation";

export function BannerClient({ banner, btn, blogs }) {
  const pathname = usePathname();
  const showBanner = pathname == "/" || pathname == '/blog/topic/panoptica' || blogs.includes(pathname);

  return (
    <>
      {showBanner && (
        <div className="bg-gradient-to-r from-[#06144D] to-[#005CAB] py-3 text-white">
          <div className="container flex min-h-14 items-center gap-3">
            <div className="w-[39px] md:mr-6 md:h-[42px]">
              <AspectRatio ratio={39 / 42}>
                <Image
                  src={btn?.icon?.data?.attributes?.url}
                  alt="Panoptica logo"
                  width={39}
                  height={42}
                  unoptimized
                ></Image>
              </AspectRatio>
            </div>
            <p className="md:font-p text-xs">{banner.header}</p>
            <Button variant="ghost" className="px-0 md:px-3" asChild>
              <LinkTrackers
                segmentMsg="Banner Link Clicked"
                segmentOpt={{
                  section_clicked: "Sunset Annoucement Banner",
                }}
                href={btn.url}
                target="_blank"
                aria-label="Learn more about Panopica"
                className="lg:font-btn flex items-center gap-1 text-xs"
              >
                <p className="hidden md:block">{btn.cta}</p>
                <div className="ml-1">
                  <ArrowLongRight size={30} />
                </div>
              </LinkTrackers>
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
